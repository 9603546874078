import React, {useEffect} from 'react'
import { graphql, Link  } from "gatsby"
//import { GatsbyImage, getImage} from "gatsby-plugin-image";
//import Helmet from 'react-helmet';
import Seo from "../components/Seo"
//import SingleVideo from '../components/SingleVideo';
import Splitting from "splitting";
import ScrollOut from "scroll-out";
import ImageRow from '../components/ImageRow';

const WpPost = ({ data, pageContext }) => {
  //console.log(data)
  console.log(pageContext)
  const { wpPost: { title, content, id, projektDetails, projektInhalte , seo}, } = data;
  const mainImg = projektInhalte.imgsRow ? projektInhalte.imgsRow[0] : null;
  const { next } = pageContext;
  
  useEffect(() => {

    document.body.classList.add('single-project');
    
    // do something
    Splitting();
    const scroll = ScrollOut({once:true});
    
    setTimeout(() => {
      scroll.update();
    },500);
    
    return () => {
      scroll.teardown();
      document.body.classList.remove('single-project');
    }
  }, []);
  

  return (
    <>
    <Seo title={seo.title} description={seo.metaDesc} content={content} />
    <div className={`post-${id}`}>

    <header className="container-fluid mb-10 mb-md-18">
      <div className="small">{projektDetails.subtitle}</div>
      <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
    </header>


      {mainImg && 
        <ImageRow   row={mainImg} bgColor={projektDetails.color} />
      }

      <div className="container-fluid mb-10 py-5 py-md-10 ">
          <div className="col-md-10_  mx-auto">
            <div className="row">
              <div className="col-md-6 col-xl-5  order-md-1_">
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
              <div className="col-md-4 col-xl-3 pt-2 offset-md-6_ offset-lg-1">
                <div className="small" dangerouslySetInnerHTML={{ __html: projektDetails.credits }} />
                { projektDetails.link &&
                  <a className="link-detail small" href={projektDetails.link}><span className="text" data-text={`→ ` + projektDetails.link?.replace('https://','')} >→ { projektDetails.link?.replace('https://','') }</span></a> 
                }
            </div>
          </div>
          </div>
     </div>
    


      {projektInhalte.imgsRow != null && projektInhalte.imgsRow.slice(1).map((row, index) => (
        
        <ImageRow key={index} row={row}  bgColor={projektDetails.color} />

        ))}

      {next &&
        <div className="container-fluid mt-10 mt-md-18">
          <div className="col-md-6 offset-md-6 col-xl-5">
            <Link to={next.uri} className="h1">
              Nächstes Projekt ↘
            </Link>
          </div>
        </div>
       } 
     
     </div>
     </>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      seo {
        title
        metaDesc
      }
      projektDetails {
        subtitle
        link
        credits
        color
      }
      projektInhalte {
        imgsRow {
          bilder {
            video {
              mediaItemUrl
              width
              height
            }
            img {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            imgBrowser
            mobile
            fullwidth
          }
        }
      }
    }
  }
`

export default WpPost