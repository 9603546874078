import React from 'react'
import { GatsbyImage, getImage} from "gatsby-plugin-image";
import SingleVideo from '../components/SingleVideo';

export default function ImageRow({ row, bgColor }) {

  return (
    <>
        <div  className="container-fluid_ px-md-8">
            
            <div className="row mb-3_ mb-md-10">
                {row.bilder.map((el, index) => (
                  
                  <div key={index} className="col-md mb-3 mb-md-0"  >
                    <div className={`h-100 `+ (el.fullwidth ? '' : 'h-100 p-5 py-md-20 px-md-30' ) +(el.mobile ? ' px-20' : '') }  style={el.imgBrowser ? {backgroundColor: bgColor}  : null}>
                      
                        <div className={ (el.imgBrowser ? 'img-browser' : '') +(el.mobile ? ' mobile' : '') }>
                            {el.video != null && 
                                  <SingleVideo src={el.video.mediaItemUrl} ratio={el.video.height / el.video.width * 100 } />
                                }
                            {el.img != null && 
                                <GatsbyImage  image={getImage(el.img.localFile)} alt="" className={`w-100 `+ ( el.imgBrowser ? 'img-browser' : '') } />
                              }
                          </div>
                         
                      </div>
                  </div>
                ))}
                </div>
            </div>
    </>
  )
}